var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import ThenPromise from 'promise';
import * as THREE from "three/src/Three.js";
// import { AmbientLight, AnimationMixer, Box3, Clock, Color, DirectionalLight, DoubleSide, Mesh, MeshStandardMaterial, Object3D, PCFSoftShadowMap, PerspectiveCamera, PointLight, Scene, Sphere, SpotLight, Vector3, WebGLRenderer } from "three";
import * as ORBITC from 'three/examples/jsm/controls/OrbitControls.js';
import * as MTLL from "./loaders/MTLLoader.js";
import * as OBJL from 'three/examples/jsm/loaders/OBJLoader.js';
import * as GLTFL from 'three/examples/jsm/loaders/GLTFLoader.js';
export class Heli3DViewer {
    constructor(_container, _assetPath, _type, _onProgress, config) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        this._container = _container;
        this._assetPath = _assetPath;
        this._type = _type;
        this._onProgress = _onProgress;
        this.config = config;
        this._radius = 10;
        this._dLights = [];
        // 3D Model
        this._model = new THREE.Object3D();
        // Animation
        this._animating = false;
        // Misc
        this._loaded = 0;
        this._error = new Error('An unknown error occurred');
        this._plight = new THREE.PointLight();
        this._spotlight = new THREE.SpotLight();
        this.endAnimation = false;
        const loadingManager = new THREE.LoadingManager(undefined, (url, loaded, total) => {
            this._loaded = (loaded / total) * 100;
            this._onProgress(this._loaded);
        });
        this._camera = new THREE.PerspectiveCamera(45, this._container.clientWidth / this._container.clientHeight, 0.1, 10000);
        this._scene = new THREE.Scene();
        this._clock = new THREE.Clock();
        this._alight = new THREE.AmbientLight((_c = (_b = (_a = config === null || config === void 0 ? void 0 : config.lights) === null || _a === void 0 ? void 0 : _a.ambient) === null || _b === void 0 ? void 0 : _b.color) !== null && _c !== void 0 ? _c : 0xffffff, (_f = (_e = (_d = config === null || config === void 0 ? void 0 : config.lights) === null || _d === void 0 ? void 0 : _d.ambient) === null || _e === void 0 ? void 0 : _e.intensity) !== null && _f !== void 0 ? _f : 1);
        this._mtlLoader = new MTLL.MTLLoader(loadingManager);
        this._objLoader = new OBJL.OBJLoader(loadingManager);
        this._gltfLoader = new GLTFL.GLTFLoader(loadingManager);
        this._renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true, preserveDrawingBuffer: false });
        this._renderer.setSize(this._container.clientWidth, this._container.clientHeight);
        this._renderer.setPixelRatio(window.devicePixelRatio);
        this._renderer.shadowMap.type = THREE.PCFSoftShadowMap;
        this._renderer.shadowMap.enabled = true;
        this._container.appendChild(this._renderer.domElement);
        this._renderer.domElement.style.background = (_h = (_g = this.config) === null || _g === void 0 ? void 0 : _g.background) !== null && _h !== void 0 ? _h : 'linear-gradient(rgb(255, 255, 255), rgb(45, 86, 104))';
    }
    init() {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function* () {
            this._camera.position.set(800, 800, 800);
            this._camera.up.set(0, 0, 1);
            // this._controls.update();
            this._controls = new ORBITC.OrbitControls(this._camera, this._renderer.domElement);
            if ((_a = this.config) === null || _a === void 0 ? void 0 : _a.orbitControl)
                this._controls.autoRotate = (_b = this.config) === null || _b === void 0 ? void 0 : _b.orbitControl.autoRotate;
            // SCENE
            if (!this._assetPath.modelPath) {
                this._error = new Error('Model Path is not defined');
                throw this._error;
            }
            if (this._type == 'OBJ' && !this._assetPath.modelPath) {
                this._error = new Error('Texture Path is not defined');
                throw this._error;
            }
            if (this._type == 'OBJ' && this._assetPath.texturePath) {
                yield this._loadOBJModel(this._assetPath.modelPath, this._assetPath.texturePath, this._mtlLoader, this._objLoader);
            }
            if (this._type == 'GLTF') {
                yield this._loadGLTFMODEL(this._assetPath.modelPath, this._gltfLoader);
            }
            // const directionHelper = new THREE.ArrowHelper(new Vector3(0,-1,0), new Vector3(0,-100,100), 300, 0xffff00);
            // this.scene.add(directionHelper);
            // DAT GUI Controls
            // this.gui = new dat.GUI({autoPlace: false})
            // guiCont.nativeElement.appendChild(this.gui.domElement);
            this._scene.add(new THREE.AxesHelper(50));
            // LIGHTS
            // Ambient
            this._scene.add(this._alight);
            if (this.config && this.config.lights) {
                if (this.config.lights.directionalLights) {
                    const lights = this.config.lights.directionalLights.lights;
                    this._dLights = lights.map(data => {
                        var _a;
                        return this._createLight(data.position.x, data.position.y, (_a = data.position) === null || _a === void 0 ? void 0 : _a.z, data.directedAt, new THREE.Color(data.color), data.intensity);
                    });
                }
                else {
                    this._dLights = [this._createLight(0, 0, this._radius, { x: 0, y: 0, z: 0 })];
                }
                if (this.config.lights.point) {
                    this._plight = new THREE.PointLight(this.config.lights.point.color, this.config.lights.point.intensity);
                    this._camera.add(this._plight);
                }
                if (this.config.lights.spotLight) {
                    const color = (_c = this.config.lights.spotLight.color) !== null && _c !== void 0 ? _c : 0xffffff;
                    const intensity = (_d = this.config.lights.spotLight.intensity) !== null && _d !== void 0 ? _d : 2;
                    const radius = this.config.lights.spotLight.radius;
                    this._spotlight = new THREE.SpotLight(new THREE.Color(color), intensity, radius !== null && radius !== void 0 ? radius : this._radius);
                    this._camera.add(this._spotlight);
                }
            }
            else {
                // this._dLights = [this._createLight(0, 0, this._radius, { x: 0, y: 0, z: 0 })]
            }
            if (this._dLights.length > 0)
                this._scene.add(...this._dLights);
            this._animate();
            this._resize();
            return {
                scene: this._scene,
                model: this._model,
                camera: this._camera,
                orbitControl: this._controls,
                renderer: this._renderer
            };
        });
    }
    _createLight(x, y, z, directedAt, color, intensity) {
        let dlight = new THREE.DirectionalLight(0x808080, 3);
        if (color && intensity) {
            dlight.color = color;
            dlight.intensity = intensity;
        }
        dlight.position.set(x, y, z);
        if (directedAt) {
            dlight.lookAt(new THREE.Vector3(directedAt.x, directedAt.y, directedAt.z));
        }
        else {
            dlight.lookAt(new THREE.Vector3(0, 0, 0));
        }
        dlight.castShadow = true;
        dlight.shadow.camera.visible = true;
        dlight.shadow.mapSize.width = 4096;
        dlight.shadow.mapSize.height = 4096;
        let d = this._radius * 2;
        dlight.shadow.camera.left = -d;
        dlight.shadow.camera.right = d;
        dlight.shadow.camera.top = d;
        dlight.shadow.camera.bottom = -d;
        dlight.shadow.camera.far = this._radius * 5;
        dlight.shadow.camera.near = 10;
        dlight.shadow.bias = -0.001;
        // dlight.shadowDarkness = 0.75;
        // dlight.target = this.model;
        // const lCamhelper = new CameraHelper(dlight.shadow.camera);
        // this.lCamhelper.push(lCamhelper);
        return dlight;
    }
    _loadOBJModel(objpath, texturepath, mtlLoader, objLoader) {
        return new ThenPromise((res, rej) => {
            // MTL Loader
            // mtlLoader.setCrossOrigin(true)
            mtlLoader.load(texturepath, (materials) => {
                let key = '';
                Object.keys(materials.materialsInfo).forEach(texture => {
                    key = texture;
                });
                materials.side = THREE.DoubleSide;
                materials.preload();
                // OBJ LOADER
                objLoader.setMaterials(materials);
                objLoader.load(objpath, (object) => {
                    var _a, _b, _c, _d, _e, _f;
                    this._model = object;
                    object.children[0].geometry.computeBoundingSphere();
                    const z = (_c = (_b = (_a = object.children[0]) === null || _a === void 0 ? void 0 : _a.geometry) === null || _b === void 0 ? void 0 : _b.boundingSphere) === null || _c === void 0 ? void 0 : _c.center.z;
                    if (z)
                        this._model.position.set(0, 0, -z);
                    const modelBoundingBox = new THREE.Box3();
                    modelBoundingBox.setFromObject(this._model);
                    let sphere = new THREE.Sphere();
                    modelBoundingBox.getBoundingSphere(sphere);
                    let radius = sphere.radius;
                    this._radius = radius;
                    if (!((_e = (_d = this.config) === null || _d === void 0 ? void 0 : _d.camera) === null || _e === void 0 ? void 0 : _e.position))
                        this._camera.position.set(radius, radius, radius);
                    (_f = this._controls) === null || _f === void 0 ? void 0 : _f.update();
                    this._model.receiveShadow = true;
                    this._model.castShadow = true;
                    this._model.children.forEach((mesh) => {
                        mesh.receiveShadow = true;
                        mesh.castShadow = true;
                        mesh.material.roughness = 1;
                        mesh.material.flatShading = true;
                    });
                    this._model.name = 'DSM';
                    this._scene.add(this._model);
                    res(this._model);
                }, (event) => {
                    // Model Download Progress
                    if (event.lengthComputable) {
                        this._loaded = (event.loaded / event.total) * 100;
                        this._onProgress(this._loaded);
                    }
                }, err => {
                    // Incase of Model Error
                    this._error = new Error(err.message);
                    rej(this._error);
                });
            }, (event) => {
                // Material Download Progress
            }, err => {
                // Incase of Material Error
                this._error = new Error(err.message);
                rej(this._error);
            });
        });
    }
    //// 3D GLTF Model
    _loadGLTFMODEL(asset, gltfLoader) {
        return new ThenPromise((res, rej) => {
            gltfLoader.load(asset, (gltf) => {
                var _a;
                this._gltf = gltf;
                this._model = gltf.scene.children[0];
                this._model.traverse((object) => {
                    console.log(object instanceof THREE.Mesh);
                    if (object instanceof THREE.Mesh) {
                        object.geometry.computeBoundingSphere();
                        // const z = object.geometry.boundingSphere.center.z;
                        // this.model.position.set(0,0,-z);
                    }
                });
                const modelBoundingBox = new THREE.Box3();
                modelBoundingBox.setFromObject(this._model);
                let sphere = new THREE.Sphere();
                modelBoundingBox.getBoundingSphere(sphere);
                let radius = sphere.radius;
                this._radius = radius;
                console.log('Radius', radius);
                this._camera.position.set(radius, radius, radius);
                (_a = this._controls) === null || _a === void 0 ? void 0 : _a.update();
                this._model.rotation.set(0, 0, 0);
                if (gltf.animations && gltf.animations.length > 0) {
                    this._mixer = new THREE.AnimationMixer(gltf.scene);
                    gltf.animations.forEach(animationClip => {
                        var _a;
                        this._animating = true;
                        const action = (_a = this._mixer) === null || _a === void 0 ? void 0 : _a.clipAction(animationClip);
                        action === null || action === void 0 ? void 0 : action.play();
                    });
                }
                this._model.name = 'DSM';
                this._scene.add(this._model);
                res(this._model);
            }, (event) => {
                // Model Download Progress
                if (event.lengthComputable) {
                    this._loaded = (event.loaded / event.total) * 100;
                }
                this._onProgress(this._loaded);
            }, (err) => {
                // Incase of Model Error
                this._error = new Error(err.message);
                rej(this._error);
            });
        });
    }
    dinit() {
        this.endAnimation = true;
        this._renderer.dispose();
        this._renderer.clear();
    }
    toggleAnimation() {
        this._animating = !this._animating;
        this._gltf.animations.forEach(animationClip => {
            var _a;
            const action = (_a = this._mixer) === null || _a === void 0 ? void 0 : _a.existingAction(animationClip);
            if (action)
                this._animating ? action.play() : action.stop();
        });
    }
    toggleResumePauseAnimation() {
        this._gltf.animations.forEach(animationClip => {
            var _a;
            const action = (_a = this._mixer) === null || _a === void 0 ? void 0 : _a.existingAction(animationClip);
            if (action)
                action.paused = !action.paused;
        });
    }
    //// RESIZE CONTAINER
    _resize() {
        this._camera.aspect = this._container.clientWidth / this._container.clientHeight;
        this._camera.updateProjectionMatrix();
        this._renderer.setSize(this._container.clientWidth, this._container.clientHeight);
    }
    //// ANIMATE
    _animate() {
        this._render();
    }
    //// RENDER
    _render() {
        if (!this.endAnimation) {
            requestAnimationFrame(() => {
                this._render();
            });
        }
        if (this._mixer)
            this._mixer.update(this._clock.getDelta());
        this._renderer.render(this._scene, this._camera);
        this._resize();
    }
}
